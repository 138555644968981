import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Cancer.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Cancer Traits
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/cancer"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Cancer</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>             
              <h4 className="text-sm"></h4>

              <h4 className="text-sm">Cancer Traits</h4>
              <h4 className="mx-2 text-sm"></h4>             
              <h4 className="text-sm"></h4>


            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Cancer Traits </h1>
                <h2 className="text-sm md:text-base ml-4">Jun 22 - Jul 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-love");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-nature");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-man");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-traits");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-facts");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">Cancer Traits</p><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Birth horoscope for free:</p>
<br/> 
Why don't you comprehend the power of Astrology to understand yourself more suitably and get a sense of directive and purpose in life? The cosmic imprint of the stars has a profound result on your life. Unravel your real prospect through the Birth Horoscope report, offered for free.
<br/><br/>
Let's understand the traits of cancer: 
 <br/><br/>
Cancer star sign, the zodiac's fourth sign, is all about home. These people are devoted to their home and family more than anything else. Cancerians are blessed with intuitive solid, and psychic capabilities that help them evaluate people well. These people tend to be tough on the outside and soft inside. But they also have a more formidable side to their personality.
 <br/><br/>
Cancers are known for their commitment, emotional depth, and parenting instincts. They are intuitive and innovative, intelligent and cautious, protective and empathetic. <br/><br/>
On the flip side of the personality, you are changeable and moody, overly emotional and touchy, clinging, and unable to let go.<br/><br/>
To better understand this highly complicated sign, look at the positive and negative Cancer traits.
 <br/><br/>
 
Let’s look at some of the positive and negative traits of Cancer for complete astrology insights:
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Cancer Positive Traits</p><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Creative</p><br/>
Cancer individuals are blessed with extensive wild fiction that can turn a phrase into a story in minutes. It’s therefore unsurprising that you are often hailed as innovative brainiacs. 
 <br/><br/>
You feel happy to share your feelings of love for an individual or something artistically through poetry and writing. You tend to have a strong creative force. You keep yourself occupied in the art to some extent. You take great delight in articulating yourself creatively and artistically.
 
 <br/><br/>
 
 <p className="font-bold text-orange-500 inline-block mr-2">Emotive</p><br/>
You are a person who believes in loyalty, your emotional depth, and your parenting instincts. You are passionate and intense; you are also highly intuitive and gracious.
 <br/><br/>
You care profoundly about issues of the family and their home. You're indulged and deeply attached to your close ones. You can empathize with the pains and sufferings of other people.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Loving and charming</p><br/>
Personality-wise, you're very loving and caring. As the multiple sensitive signs of the zodiac, Cancer does everything with devotion in mind and in the heart. Because of this, you are the most delicate lover. You are fantastic and magical partners when your mood is up. 
 <br/><br/>
They are witty, emotional, insightful, adventurous, and indulgent, and very well know how to have a good time.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Intuitive</p><br/>
You have a phenomenal sense of intuition. You know very well what is in the mind of others and understand what they did. It is very challenging to hoodwink and manipulate you. Your instinctive capability to sniff out the secrets drives you over-sensitive. When someone tries to hide secrets from you, you will keep questioning until you get out of the unknown, but this can make you feel heavy or drained.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Tender Hearted</p><br/>
Because of your shyness, you find difficulty in communicating your absolute emotions. You shut down your emotional reactions when you are hurt. You seem to be emotionally immature at times but uniquely sensorial. You will be overly involved and connected to those positively susceptible to their sentiments. 
 <br/><br/>
You are highly sympathetic as you comprehend other people's emotional states and feelings. You feel others' feelings as your own.
 <br/><br/>
To know more about your personalized Janampatri, you can get an in-depth understanding of life.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Cancer Negative Traits</p><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Moody</p><br/>
Cancer individuals are also known for their jealous behavior, and this behavior comes into their heads when they are not in a good mood; everything revolves around the spirit. Their frequent mood swings and behavior puts them in trouble for some time. You can be very brooding and moody if you feel your emotional needs are not being met. You switch moods rapidly. You will be laughing and joking at the moment and shedding your tears after a few minutes. This is part of your everyday life that rides like waves, and no one quickly understands this criterion.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Negative</p><br/>
You are ruled by the Moon, which is ever-changing, and thus you can have moods that can grow dark and darker. 
 <br/><br/>
It's ordinary for you to struggle with low self-esteem and hold a lifelong grievance against someone. As a person, you're negative in so many things, like understanding with an example of a glass of water. You are someone who sees the glass as half empty rather than half full. The wound of hurt and harm of feelings never get healed.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Clingy</p><br/>
Cancer individuals are occasionally over-imaginative, which can get them in trouble. You will cling and torment the situation until you have all the solutions. You can't turn off the feelings once it is ignited.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Unpredictable</p><br/>
No one can predict your mood because of your you have extreme mood swings, as well as sensitiveness. It doesn't take much for you to go from friendly and outgoing to introverted as a way of defending yourself. And it can be pretty incoherent and unpredictable to the people in your life.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Suspicious</p><br/>
The empathetic crab is inclined to protect itself. You only trust when you feel safe within connections. When you do not feel accepted or safe, you often resort to your inner world.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Resentful</p><br/>
You become resentful and petulant often. Whenever you feel that you have been diverted, you will gradually build up resentment until it explodes completely. The most significant problem is anything can make you upset. You have great fertile imaginations, but you occasionally use these talents destructively. It is questioning to please you. You snarl the situation out and come back to normal in time.
 <br/><br/>
 
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
